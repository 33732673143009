<template>
  <nav
    class="c-mainNavBeforeLogin"
    v-intersect-translate.once
  >
    <ul class="c-mainNavBeforeLogin_list">
      <li class="c-mainNavBeforeLogin_item c-mainNavBeforeLogin_item-search">
        <a
          href="javascript:;"
          @click="$emit('click-search')"
        >
          <CoconalaIcon
            name="search"
            size="22px"
          />
        </a>
      </li>
      <li class="c-mainNavBeforeLogin_item c-mainNavBeforeLogin_item-help">
        <BalloonDropdown
          position="bottom-left"
          @active-change="updateActiveMenu('help', $event)"
          :is-active="isActiveMenu('help')"
          :triggers="[]"
        >
          <a
            href="javascript:;"
            slot="trigger"
            @click="toggleMenu('help')"
          >
            <OTooltip
              label="サポート"
              variant="black"
              content-class="is-small"
              position="bottom"
              :active="!isActiveMenu('help')"
            >
              <CoconalaIcon
                name="question-circle-o"
                size="22px"
              />
            </OTooltip>
          </a>
          <ClientOnly>
            <MenuHelp
              v-if="isActiveMenu('help')"
              :header-type="headerType"
            />
          </ClientOnly>
        </BalloonDropdown>
      </li>
      <li class="c-mainNavBeforeLogin_item">
        <div class="c-mainNavBeforeLogin_button-pc">
          <DButton
            :href="$coconala.loginURL($route.fullPath)"
            color="primary"
            size="small"
            type="outlined3"
            is-bold
            rel="nofollow"
          >
            ログイン
          </DButton>
          <DButton
            :href="registrationURL"
            size="small"
            is-bold
            rel="nofollow"
          >
            会員登録
          </DButton>
        </div>
        <div class="c-mainNavBeforeLogin_button-sp">
          <DButton
            :href="$coconala.loginURL($route.fullPath)"
            color="primary"
            size="small"
            type="outlined3"
            is-bold
            rel="nofollow"
          >
            ログイン
          </DButton>
          <DButton
            :href="registrationURL"
            size="small"
            is-bold
            rel="nofollow"
          >
            会員登録
          </DButton>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
import DButton from '~/components/atoms/DS2/DButton'
import BalloonDropdown from '~/components/molecules/BalloonDropdown'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MainNavBeforeLogin',
  components: {
    DButton,
    BalloonDropdown,
    MenuHelp: () => import('./MenuHelp')
  },
  props: {
    headerType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentActiveMenu: null
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC']),
    ...mapState('constants', ['HEADER_TYPE']),
    ...mapState('pages/search/search', ['searchCondition']),
    registrationURL() {
      const params = { ref: 'header_menu' }

      if (this.$route.path === '/') {
        // トップページの時はrefの値を変える
        params['ref'] = 'header_menu_top'
      }

      // サービス検索・カテゴリページの場合、絞り込み状態のパラメータ追加
      const SERVICE_SEARCH_PAGES = [
        'search',
        'categories-categoryId',
        'categories-categoryId-categoryTypeId'
      ]
      if (SERVICE_SEARCH_PAGES.includes(this.$route.name)) {
        if (this.searchCondition.masterCategoryId) {
          params.master_category_id = this.searchCondition.masterCategoryId
        }
        if (this.searchCondition.serviceKind) {
          params.service_kind = this.searchCondition.serviceKind
        }
      }

      return this.$coconala.registrationURL(this.$route.fullPath, params)
    }
  },
  watch: {
    '$route.path'() {
      // mountedだけではNuxtLinkでページ遷移した時に対応できないため
      this.addImpressionLog()
    }
  },
  mounted() {
    this.addImpressionLog()
  },
  methods: {
    updateActiveMenu(menuName, isActive) {
      if (isActive) {
        this.currentActiveMenu = menuName
      } else if (this.currentActiveMenu === menuName) {
        this.currentActiveMenu = null
      }
    },
    isActiveMenu(menuName) {
      return this.currentActiveMenu === menuName
    },
    openMenu(menuName) {
      this.currentActiveMenu = menuName
    },
    closeMenu() {
      this.currentActiveMenu = null
    },
    toggleMenu(menuName) {
      if (this.isActiveMenu(menuName)) {
        this.closeMenu()
      } else {
        this.openMenu(menuName)
      }
    },
    touchMenu(menuName, event) {
      // タッチデバイスではmouseenterとclickが連続で発生してしまうため、それらは使わないようここで止める
      event.preventDefault()
      this.toggleMenu(menuName)
    },
    async addImpressionLog() {
      if (!this.isPC) return
      try {
        await this.$store.dispatch('big_query/log_impression/addLogImpression', {
          action: 'view_header_ef_route'
        })
      } catch (e) {
        this.$sentry.captureException(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-mainNavBeforeLogin {
  margin-left: auto;
  padding-left: 12px;

  a {
    color: $ds2-color-gray-600;

    &:not(.d-button) {
      @include link-effect-background;
    }
  }

  .d-button {
    width: 84px;
    white-space: nowrap;

    & + .d-button {
      margin-left: 8px;
    }
  }

  &_list {
    display: flex;
  }

  &_item {
    display: flex;

    align-items: center;

    &-search {
      display: none;
    }

    &-manage {
      margin-right: 4px;
      font-weight: bold;
      font-size: 13px;
    }

    &-help {
      margin: 0 12px 1px 0;

      .coconala-icon {
        &::before {
          vertical-align: -2px;
        }
      }
    }

    ::v-deep .dropdown {
      &-content {
        padding: 24px 0;
      }

      &-item {
        padding: 0 16px;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
      }
    }
  }

  &_button-pc {
    display: flex;
    white-space: nowrap;
  }

  .c-balloonDropdown {
    a {
      display: flex;

      align-items: center;
    }
  }
}

@media (max-width: breakpoint(Header, M)) {
  .c-mainNavBeforeLogin {
    .c-mainNavBeforeLogin {
      &_item {
        &-manage {
          display: none;
        }
      }
    }
  }
}

@media (max-width: breakpoint(Header, S)) {
  .c-mainNavBeforeLogin {
    padding-left: 0;

    &_item {
      &-search {
        display: flex;
        margin-right: 4px;

        .coconala-icon {
          &::before {
            vertical-align: -1px;
          }
        }
      }

      &-help {
        display: none;
      }
    }

    .d-button {
      width: 72px;
    }
  }
}

@media (max-width: breakpoint(Home, M)) {
  .c-mainNavBeforeLogin {
    &_button {
      &-pc {
        display: none;
      }
      &-sp {
        display: flex;
        white-space: nowrap;
      }
    }
  }
}

@media (min-width: breakpoint(Home, M) + 1) {
  .c-mainNavBeforeLogin {
    &_button {
      &-sp {
        display: none;
      }
    }
  }
}

@media (max-width: 374px) {
  .c-mainNavBeforeLogin {
    .d-button {
      width: 64px;
    }
  }
}

// NOTE: IE hack
*::-ms-backdrop,
.c-mainNavBeforeLogin {
  position: absolute;
  top: 14px;
  right: 24px;
}
</style>
