<template>
  <div
    class="c-balloonDropdown"
    :class="classes"
  >
    <ODropdown
      ref="dropdown"
      v-bind="$attrs"
      v-on="$listeners"
      :mobile-modal="false"
      @active-change="onActiveChange($event)"
      @click.native="onClick"
      :position="newPosition"
      :triggers="triggers"
    >
      <slot
        v-for="slotName in Object.keys($slots)"
        :name="slotName"
        :slot="slotName"
      ></slot>
    </ODropdown>
  </div>
</template>
<script>
export const POSITIONS = [
  'top-center',
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
  'bottom-center'
]

export default {
  name: 'BalloonDropdown',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'bottom-right',
      validator(v) {
        return POSITIONS.includes(v)
      }
    },
    width: {
      type: String
    },
    closable: {
      type: Boolean,
      default: true
    },
    triggers: {
      type: Array,
      default() {
        return ['click']
      }
    }
  },
  data() {
    return {
      $menuEl: null,
      menuWidth: 0
    }
  },
  mounted() {
    this.$refs.dropdown.isActive = this.isActive
    this.$menuEl = this.$el.querySelector('.dropdown-menu')
    this.setWidth()

    if (this.needsCentering) {
      this.$nextTick(this.centering)
    }
  },
  methods: {
    onClick() {
      if (!this.closable) return
      // this.$refs.dropdown.isActive = false
      this.$emit('click')
    },
    centering() {
      this.$menuEl.style.left = '50%'
      this.$menuEl.style.marginLeft = `-${this.menuWidth / 2}px`
    },
    setWidth() {
      if (this.width) this.$menuEl.style.width = this.width
      this.menuWidth = this.$menuEl.offsetWidth
    },
    onActiveChange(isActive) {
      this.$emit('update:isActive', isActive)
      this.$nextTick(() => {
        this.setWidth()
        if (this.needsCentering) this.centering()
      })
    }
  },
  watch: {
    isActive(v) {
      this.$refs.dropdown.isActive = v
    }
  },
  computed: {
    classes() {
      const classes = {
        'is-fixed-width': !!this.width,
        [`${this.position}`]: true
      }
      return classes
    },
    newPosition() {
      if (this.needsCentering || this.position === 'bottom-right') {
        return null
      }
      return this.position
    },
    needsCentering() {
      return ['top-center', 'bottom-center'].includes(this.position)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-balloonDropdown {
  &[class*='top-'] {
    ::v-deep .dropdown {
      &-menu {
        top: auto;
        bottom: calc(100% + 5px);
      }
      &-trigger {
        &::after {
          @include arrow(8px, down);

          top: -8px;
          bottom: 'auto';
          display: none;
        }
      }
      &-content {
        &::after {
          position: absolute;
          top: 100%;
          left: 0;
          display: block;
          width: 100%;
          height: 12px;
          content: '';
        }
      }
    }
  }
  &[class*='bottom-'] {
    ::v-deep .dropdown {
      &-content {
        &::before {
          position: absolute;
          top: -8px;
          left: 0;
          display: block;
          width: 100%;
          height: 12px;
          content: '';
        }
      }
    }
  }

  &.is-fixed-width {
    ::v-deep .dropdown {
      &-menu {
        min-width: auto;
      }
    }
  }

  ::v-deep .dropdown {
    &.is-hoverable {
      &:hover {
        .dropdown-trigger {
          &::after {
            display: block;
          }
        }
      }
    }
    &.is-active {
      .dropdown-trigger {
        &::after {
          display: block;
        }
      }
    }
    &-menu {
      top: calc(100% + 4px);
    }
    &-trigger {
      position: relative;
      &::after {
        @include arrow(8px);

        position: absolute;
        bottom: -8px;
        left: calc(50% - 4px);
        z-index: z(dropdown, arrow);
        display: none;
      }
    }
    &-content {
      position: absolute;
      z-index: z(dropdown, content);
      border: solid 1px $ds2-color-gray-25;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    }
    &-item,
    .has-link {
      position: static;
      flex: 1;
      color: $ds2-color-font-base;
      font-size: 13px;
      &.-title {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
</style>
